<form [formGroup]="selectForm">
    <mat-form-field [ngClass]="cssClass" class="mg-10" style="width: 10rem;">
        <mat-label>{{ label }}</mat-label>
        <mat-select #select formControlName="select"
                    (selectionChange)="emitChange($event)">
          <mat-option *ngFor="let x of data$ | async" [value]="x.id">
            {{ x.name }}
          </mat-option>
          <mat-option *ngFor="let x of syncData" [value]="x.id">
            {{ x.name }}
          </mat-option>
        </mat-select>
    </mat-form-field>
</form>
