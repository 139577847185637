import { Injectable } from "@angular/core";
import { HttpInterceptor } from "@angular/common/http";
import { HttpRequest } from "@angular/common/http";
import { HttpHandler } from "@angular/common/http";
import { Observable } from "rxjs";
import { HttpSentEvent } from "@angular/common/http";
import { Inject } from "@angular/core";
import { HttpHeaderResponse } from "@angular/common/http";
import { HttpProgressEvent } from "@angular/common/http";
import { HttpResponse } from "@angular/common/http";
import { HttpUserEvent } from "@angular/common/http";
import { LocationStrategy, DOCUMENT } from '@angular/common';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {

    constructor(@Inject(DOCUMENT) private readonly document: any,
        private readonly locationStrategy: LocationStrategy,
        @Inject("ENVIRONMENT") private readonly environment: any) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

        let apiReq;
        if (this.environment.localUrl !== undefined) {
            apiReq = req.clone({ url: `${this.environment.localUrl}${req.url}` });
        }
        else {
            apiReq = req.clone({ url: `${this.document.location.origin}${this.locationStrategy.getBaseHref()}api${req.url}` });
        }

        return next.handle(apiReq);
    }
}