import { Component, ElementRef, ViewChild, OnInit, ViewChildren, QueryList, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { PlansDialogueComponent } from './plans-dialogue/plans-dialogue.component';
import { PlanTypeClass, PlanTypeAge, BenefitOptionRequired, SalaryEmptyCheck, FamilyOptOutCheck } from './CustomValidators';
import { v4 as uuidv4 } from 'uuid';
import * as download from '../../assets/js/download.js';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { StepperSelectionEvent } from "@angular/cdk/stepper";
import { ReferenceDataService } from "src/app/services/http-clients/reference-data.service";
import { FamilyStatus } from "src/app/questions/classes/family-status.class";
import { Gender } from "src/app/questions/classes/gender.class";
import { Province } from "src/app/questions/classes/province.class";
import { CommissionRates } from "src/app/questions/classes/commission.class";
import { ValidDate } from './classes/valid-date.class';
import { PlanType } from "src/app/questions/classes/plan-type.class";
import { Observable, from, of } from "rxjs";
import { CurrentProvider } from './classes/current-provider.class';
import MassSelectChangeEvent from './classes/events/mass-select-change.event';
import { ClassType } from './classes/class-type.class';
import { MassSelectComponent } from './mass-select/mass-select.component';
import { EmployeeAssistance } from './classes/employee-assistance.class';
import { QuoteDataService } from '../services/http-clients/quote-data.service';
import { SystemVariable } from './classes/system-variable.class';
import { formatDate } from '@angular/common';
import { QuoteInputDto } from './classes/dto/quote-input-dto';
import { BenefitOption } from './classes/benefit-option.class';
import { MassInputComponent } from './mass-input/mass-input.component';

const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class QuestionsComponent implements OnInit {
  questionsForm: FormGroup;
  generalInfoForm: FormGroup;
  employeeInfoForm: FormGroup;
  optionsData: FormGroup;
  termsForm: FormGroup;
  classes: FormGroup;
  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  planTypeClass = new PlanTypeClass();
  planTypeAge = new PlanTypeAge();
  benefitOptionReq = new BenefitOptionRequired();
  salaryEmptyCheck = new SalaryEmptyCheck();
  optOutCheck = new FamilyOptOutCheck();
  quoteInput = new QuoteInputDto();
  birthDateFocus = new Date(1990, 0, 1);
  birthDateMin;
  birthDateMax = new Date();
  quoteAmount = 0;
  displayError = "";
  screenWidth: number;
  uuid = uuidv4();
  showOptionTwo: boolean = false;
  generatingQuote = false;
  loading = true;
  downloadInProgress = false;
  hcsaOption = true;
  psaOption = true;
  effectiveDateStart: Moment = this.getEffectiveDateStart();
  effectiveDateEnd: Moment = this.getEffectiveDateEnd();
  validDates$: Observable<ValidDate[]>;
  massChangedValues = new Map();
  @ViewChildren(MassSelectComponent) massSelects: QueryList<MassSelectComponent>;
  @ViewChild(MassInputComponent) massInput: MassInputComponent;

  //Supporting Data
  familyStatuses$: Observable<FamilyStatus[]>;
  genders$: Observable<Gender[]>;
  planTypes$: Observable<PlanType[]>;
  salaryAmount$: Observable<number>;
  provinces$: Observable<Province[]>;
  commissions$: Observable<CommissionRates[]>;
  currentProviders$: Observable<CurrentProvider[]>;
  classTypes: ClassType[] = [{ id: 1, name: "Class 1" }, { id: 2, name: "Class 2" }];
  optOutOptions: ClassType[] = [{ id: 1, name: "Include full coverage" }, { id: 2, name: "Waive coverage for health only" },
  { id: 3, name: "Waive coverage for dental only" }, { id: 4, name: "Waive coverage for health and dental" }];
  options: BenefitOption[] = [{ id: null, name: "Select One" }, { id: 1, name: "Option 1" }, { id: 2, name: "Option 2" },
  { id: 3, name: "Option 3" }, { id: 4, name: "Option 4" }, { id: 5, name: "Option 5" }];
  eapClasses: EmployeeAssistance[];
  eapCost: SystemVariable;

  selectedRange: number;
  maxEmployeeVariable: SystemVariable;
  minEmployeeVariable: SystemVariable;
  instanceValue: SystemVariable;
  quoteSuccess: boolean;
  constructor(private fb: FormBuilder, private http: HttpClient,
    public dialog: MatDialog, private dataService: ReferenceDataService,
    private quoteDataClient: QuoteDataService) {
  }

  ngOnInit() {
    this.selectedRange = 1;
    this.familyStatuses$ = this.dataService.getFamilyStatuses();
    this.genders$ = this.dataService.getGenders();
    this.planTypes$ = this.dataService.getPlanTypes();
    this.salaryAmount$ = new Observable<number>();
    this.provinces$ = this.dataService.getProvinces();
    this.commissions$ = this.dataService.getCommissions();
    this.currentProviders$ = this.dataService.getCurrentProviders();
    this.validDates$ = this.getEffectiveDates();
    this.quoteDataClient.getQuoteVariables().subscribe(o => {
      this.maxEmployeeVariable = o.find(x => x.argument === SystemVariable.MaxEmployeeCount);
      this.minEmployeeVariable = o.find(x => x.argument === SystemVariable.MinEmployeeCount);
      this.instanceValue = o.find(x => x.argument.toUpperCase() === SystemVariable.InstanceName);
      this.eapCost = o.find(x => x.argument === SystemVariable.EmployeeAssistanceRate);

      this.initEmployeeForm();
    });
    this.getScreenSize();

    this.initForm();
  }

  initForm() {
    this.effectiveDateStart = this.getEffectiveDateStart();
    const format = 'MMMM dd yyyy';
    const locale = 'en-US';

    this.generalInfoForm = this.fb.group({
      brokerName: ['', [Validators.required, Validators.maxLength(100)]],
      provinceId: [3, Validators.required],
      proposedEffectiveDate: [formatDate(this.getEffectiveDateStart().toString(), format, locale), Validators.required],
      brokerEmail: ['', [Validators.required, Validators.email, Validators.maxLength(100)]],
      businessName: ['', [Validators.required, Validators.maxLength(25)]],
      commissionAmount: [1, Validators.required]
    });

    this.employeeInfoForm = this.fb.group({
      employees: this.fb.array([]),
      eapClasses: this.fb.array([])
    });

    this.optionsData = this.fb.group({
      addClass1: [null, Validators.required],//1
      addClass2: null,
      healthClass1: [null, Validators.required],//2
      healthClass2: null,
      dependentClass1: [null, Validators.required], //3
      dependentClass2: null,
      ciClass1: [null, Validators.required],//4
      ciClass2: null,
      ltdClass1: [null, Validators.required],//5
      ltdClass2: null,
      dentalClass1: [null, Validators.required],//6
      dentalClass2: null,
      eapClass1: [null, Validators.required],//7
      eapClass2: null,
      teleMedClass1: [null, Validators.required],//8
      teleMedClass2: null,
      expertMedClass1: [null, Validators.required],//9
      expertMedClass2: null,
      hcsaClass1: [null, Validators.required],//10
      hcsaClass2: null,
      psaClass1: [null, Validators.required],//11
      psaClass2: null,
      hcsaAmount1: [null, Validators.required],
      hcsaAmount2: null,
      psaAmount1: [null, Validators.required],
      psaAmount2: null
    });

    this.termsForm = this.fb.group({
      accepts: ['', Validators.requiredTrue]
    });

    this.eapClasses = [];
    this.classTypes.forEach((e, i) => {
      this.eapClassArray.push(this.fb.group({
        class: ['']
      }));

      let eapClass = new EmployeeAssistance();
      eapClass.id = e.id;
      eapClass.selected = false;
      this.eapClasses.push(
        eapClass
      )
    });

    this.initEmployeeForm();
  }

  initEmployeeForm() {
    for (let i = 0; i < Number(this.minEmployees); ++i) {
      this.addEmployee();
    }
  }

  addEmployee() {
    if (this.employees.length === this.maxEmployees) return;

    const employee = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      dateOfBirth: ['', [Validators.required, PlanTypeAge.validate]],
      salaryAmountId: [this.massChangedValues.has('salaryAmountId') ? this.massChangedValues.get('salaryAmountId') : null, [Validators.required, Validators.min(0.01), Validators.maxLength(100), SalaryEmptyCheck.validate]],
      genderId: [this.massChangedValues.has('genderId') ? this.massChangedValues.get('genderId') : '', Validators.required],
      familyStatusId: [this.massChangedValues.has('familyStatusId') ? this.massChangedValues.get('familyStatusId') : '', Validators.required],
      classId: [this.massChangedValues.has('classId') ? this.massChangedValues.get('classId') : 1, [Validators.required]],
      optOutId: [1, [Validators.required]],
      employeeAssistanceProgram: [false]
    }, { validators: [PlanTypeClass.validate, FamilyOptOutCheck.validate] });

    this.employees.push(employee);
  }

  submit() {
    this.generatingQuote = true;
    this.quoteSuccess = false;
    this.setData();
    this.quoteDataClient.submitQuote(this.quoteInput).subscribe({
      next: response => {
        this.quoteSuccess = true;
        this.quoteAmount = response;
        this.generatingQuote = false;
      },
      error: (error: HttpErrorResponse) => {
        this.displayError = "An unexpected error occured, please try again later, or contact admin";
        this.quoteSuccess = false;
        this.generatingQuote = false;
      }
    });
  }

  reset() {
    const brokerName = this.generalInfoForm.get('brokerName').value;
    const brokerEmail = this.generalInfoForm.get('brokerEmail').value;
    const provinceId = this.generalInfoForm.get('provinceId').value;

    this.stepper.reset();
    this.stepper.selectedIndex = 1;

    this.massChangedValues.set('genderId', null);
    this.massChangedValues.set('familyStatusId', null);
    this.massChangedValues.set('classId', 1);
    this.massChangedValues.set('salaryAmountId', null);

    this.massSelects.forEach((el) => {
      el.reset();
    });
    this.massInput.reset();

    this.initForm();

    this.generalInfoForm.get('brokerName').setValue(brokerName);
    this.generalInfoForm.get('brokerEmail').setValue(brokerEmail);
    this.generalInfoForm.get('provinceId').setValue(provinceId);
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenWidth = window.innerWidth;
  }

  getEffectiveDateStart() {
    const today = new Date();
    return moment(new Date(today.getFullYear(), today.getMonth() + 1, 1));
  }

  getEffectiveDateEnd() {
    return this.effectiveDateStart.add(2, 'month')
  }

  getEffectiveDates(): Observable<ValidDate[]> {
    const format = 'MMMM dd yyyy';
    const locale = 'en-US';

    var options = [
      new ValidDate(),
      new ValidDate(),
      new ValidDate()
    ];

    options[0].id = 1;
    options[1].id = 2;
    options[2].id = 3;

    options[0].value = formatDate(this.getEffectiveDateStart().toString(), format, locale);
    options[1].value = formatDate(this.getEffectiveDateStart().add(1, 'month').toString(), format, locale);
    options[2].value = formatDate((this.effectiveDateEnd).toString(), format, locale);

    return of(options);
  }

  showTenDayWarning() {
    const proposed = new Date(this.proposedEffectiveDate.value);
    if (proposed === null) return;

    const today = new Date();
    var differenceInMs = proposed.getTime() - today.getTime();

    // Convert milliseconds to days
    var differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);//ms per s, s per m, m per h, h per day

    return differenceInDays < 10;
  }

  showOptOutWarning() {
    const formGroups = this.employees.controls as FormGroup[];

    for (const group of formGroups) {
      if (group.value['optOutId'] !== 1) {
        return true;
      }
    }

    return false;
  }

  showClassWarning() {
    const formGroups = this.employees.controls as FormGroup[];
    for (const group of formGroups) {
      if (group.value['classId'] === 1) {
        return false;
      }
    }

    return true;
  }

  setData() {
    this.quoteInput.CompanyInfo = {
      BrokerName: this.generalInfoForm.get('brokerName').value,
      BrokerEmail: this.generalInfoForm.get('brokerEmail').value,
      BusinessName: this.generalInfoForm.get('businessName').value,
      Province: this.generalInfoForm.get('provinceId').value,
      CommissionPercent: this.generalInfoForm.get('commissionAmount').value,
      StartDate: new Date(this.proposedEffectiveDate.value)
    };

    const emps = this.employees.controls as FormGroup[];
    this.quoteInput.Employees = new Array();

    for (let i = 0; i < this.employees.length; i++) {
      var employee = emps[i];
      this.quoteInput.Employees[i] = {
        EmployeeName: employee.get('name').value,
        DateOfBirth: employee.get('dateOfBirth').value,
        AnnualSalary: employee.get('salaryAmountId').value,
        Gender: employee.get('genderId').value,
        FamilyStatus: employee.get('familyStatusId').value,
        CoverageOptOut: {
          OptOutOfDental: employee.get('optOutId').value === 3 || employee.get('optOutId').value === 4,
          OptOutOfHealth: employee.get('optOutId').value === 2 || employee.get('optOutId').value === 4
        },
        Class: employee.get('classId').value
      };
    }

    this.quoteInput.BenefitOptions = {
      BasicLife: {
        Class1: {
          SelectedOption: this.optionsData.get('addClass1').value,
          SpendingAccountAmount: 0
        },
        Class2: {
          SelectedOption: this.showOptionTwo ? this.optionsData.get('addClass2').value : 0,
          SpendingAccountAmount: 0
        }
      },
      Add: {
        Class1: {
          SelectedOption: this.optionsData.get('addClass1').value,
          SpendingAccountAmount: 0
        },
        Class2: {
          SelectedOption: this.showOptionTwo ? this.optionsData.get('addClass2').value : 0,
          SpendingAccountAmount: 0
        }
      },
      DependentLife: {
        Class1: {
          SelectedOption: this.optionsData.get('dependentClass1').value,
          SpendingAccountAmount: 0
        },
        Class2: {
          SelectedOption: this.showOptionTwo ? this.optionsData.get('dependentClass2').value : 0,
          SpendingAccountAmount: 0
        }
      },
      CI: {
        Class1: {
          SelectedOption: this.optionsData.get('ciClass1').value,
          SpendingAccountAmount: 0
        },
        Class2: {
          SelectedOption: this.showOptionTwo ? this.optionsData.get('ciClass2').value : 0,
          SpendingAccountAmount: 0
        }
      },
      LTD: {
        Class1: {
          SelectedOption: this.optionsData.get('ltdClass1').value,
          SpendingAccountAmount: 0
        },
        Class2: {
          SelectedOption: this.showOptionTwo ? this.optionsData.get('ltdClass2').value : 0,
          SpendingAccountAmount: 0
        }
      },
      Health: {
        Class1: {
          SelectedOption: this.optionsData.get('healthClass1').value,
          SpendingAccountAmount: 0
        },
        Class2: {
          SelectedOption: this.showOptionTwo ? this.optionsData.get('healthClass2').value : 0,
          SpendingAccountAmount: 0
        }
      },
      Dental: {
        Class1: {
          SelectedOption: this.optionsData.get('dentalClass1').value,
          SpendingAccountAmount: 0
        },
        Class2: {
          SelectedOption: this.showOptionTwo ? this.optionsData.get('dentalClass2').value : 0,
          SpendingAccountAmount: 0
        }
      },
      EAP: {
        Class1: {
          SelectedOption: this.optionsData.get('eapClass1').value,
          SpendingAccountAmount: 0
        },
        Class2: {
          SelectedOption: this.showOptionTwo ? this.optionsData.get('eapClass2').value : 0,
          SpendingAccountAmount: 0
        }
      },
      ExpertMed: {
        Class1: {
          SelectedOption: this.optionsData.get('expertMedClass1').value,
          SpendingAccountAmount: 0
        },
        Class2: {
          SelectedOption: this.showOptionTwo ? this.optionsData.get('expertMedClass2').value : 0,
          SpendingAccountAmount: 0
        }
      },
      TeleMed: {
        Class1: {
          SelectedOption: this.optionsData.get('teleMedClass1').value,
          SpendingAccountAmount: 0
        },
        Class2: {
          SelectedOption: this.showOptionTwo ? this.optionsData.get('teleMedClass2').value : 0,
          SpendingAccountAmount: 0
        }
      },
      HCSA: {
        Class1: {
          SelectedOption: this.optionsData.get('hcsaClass1').value,
          SpendingAccountAmount: this.optionsData.get('hcsaClass1').value == 1 ? this.optionsData.get('hcsaAmount1').value : 0
        },
        Class2: {
          SelectedOption: this.showOptionTwo ? this.optionsData.get('hcsaClass2').value : 0,
          SpendingAccountAmount: this.showOptionTwo && this.optionsData.get('hcsaClass2').value == 1 ? this.optionsData.get('hcsaAmount2').value : 0,
        }
      },
      PSA: {
        Class1: {
          SelectedOption: this.optionsData.get('psaClass1').value,
          SpendingAccountAmount: this.optionsData.get('psaClass1').value == 1 ? this.optionsData.get('psaAmount1').value : 0
        },
        Class2: {
          SelectedOption: this.showOptionTwo ? this.optionsData.get('psaClass2').value : 0,
          SpendingAccountAmount: this.showOptionTwo && this.optionsData.get('psaClass2').value == 1 ? this.optionsData.get('psaAmount2').value : 0,
        }
      }
    }
    this.quoteInput.UUID = this.uuid;
  }

  getDocument(extension: string = 'zip') {
    const body = document.querySelectorAll('body')[0];
    body.style.cursor = 'progress';
    this.downloadInProgress = true;
    const fileName = `${'Proposal_Package' + '_' + this.generalInfoForm.get('businessName').value.replace(' ', '_')}.${extension}`;
    this.http.post(`/Documents/GeneratePdf`, this.quoteInput, { responseType: 'blob' })
      .subscribe((response: any) => {
        download(response, fileName, "");
        body.style.cursor = 'default';
        this.downloadInProgress = false;
      });
  }

  stepHandler(event: any) {
    if (4 === event.selectedIndex) {
      this.submit();
    }
  }

  removeEmployee(index) {
    this.employees.removeAt(index);
  }

  getBirthDateMin(): Moment {
    const effectiveDate: Moment = moment(this.proposedEffectiveDate.value);

    return effectiveDate.add(-70, 'years');
  }

  updateEmployeesValidity() {
    for (var i = 0; i < this.employees.length; i++) {
      let employee = this.employees.at(i);
      employee.updateValueAndValidity();
      employee.markAsDirty();
    }
    this.showOptionTwoCheck();
  }

  massChange(event: MassSelectChangeEvent) {
    Object.keys(this.employees.controls).forEach(key => {
      const group = (this.employees.controls[key]);
      if (event.controlName !== undefined && event.value !== undefined) {
        this.massChangedValues.set(event.controlName, event.value)

        group.get(event.controlName).setValue(event.value);
      }
    });
    this.updateEmployeesValidity();
  }

  updateEmployeeAssistanceProgramSelection(classId: number, checked: boolean) {
    this.eapClasses.find((x) => x.id == classId).selected = checked;
  }

  updateEmployeesForEap() {
    for (let index = 0; index < this.employees.length; index++) {
      const element = this.employees.at(index);

      element.get('employeeAssistanceProgram').setValue(this.eapClasses.find((x) => x.id == element.get('class').value).selected);
    }
  }

  editQuote() {
    this.stepper.selectedIndex = 2;
  }

  showOptionTwoCheck() {
    this.showOptionTwo = false;
    const emps = this.employees.controls as FormGroup[];
    for (let i = 0; i < this.employees.length; i++) {
      let employee = emps[i];
      if (this.showOptionTwo !== true && employee.get('classId').value === 2) {
        this.showOptionTwo = true;
        this.optionsData.get('healthClass2').setValidators(Validators.required);
        this.optionsData.get('healthClass2').updateValueAndValidity();
        this.optionsData.get('addClass2').setValidators(Validators.required);
        this.optionsData.get('addClass2').updateValueAndValidity();
        this.optionsData.get('dependentClass2').setValidators(Validators.required);
        this.optionsData.get('dependentClass2').updateValueAndValidity();
        this.optionsData.get('ciClass2').setValidators(Validators.required);
        this.optionsData.get('ciClass2').updateValueAndValidity();
        this.optionsData.get('ltdClass2').setValidators(Validators.required);
        this.optionsData.get('ltdClass2').updateValueAndValidity();
        this.optionsData.get('dentalClass2').setValidators(Validators.required);
        this.optionsData.get('dentalClass2').updateValueAndValidity();
        this.optionsData.get('eapClass2').setValidators(Validators.required);
        this.optionsData.get('eapClass2').updateValueAndValidity();
        this.optionsData.get('teleMedClass2').setValidators(Validators.required);
        this.optionsData.get('teleMedClass2').updateValueAndValidity();
        this.optionsData.get('expertMedClass2').setValidators(Validators.required);
        this.optionsData.get('expertMedClass2').updateValueAndValidity();
        this.optionsData.get('hcsaClass2').setValidators(Validators.required);
        this.optionsData.get('hcsaClass2').updateValueAndValidity();
        this.optionsData.get('psaClass2').setValidators(Validators.required);
        this.optionsData.get('psaClass2').updateValueAndValidity();
      }
    }

    if (!this.showOptionTwo) {
      this.optionsData.get('healthClass2').clearValidators();
      this.optionsData.get('healthClass2').updateValueAndValidity();
      this.optionsData.get('addClass2').clearValidators();
      this.optionsData.get('addClass2').updateValueAndValidity();
      this.optionsData.get('dependentClass2').clearValidators();
      this.optionsData.get('dependentClass2').updateValueAndValidity();
      this.optionsData.get('ciClass2').clearValidators();
      this.optionsData.get('ciClass2').updateValueAndValidity();
      this.optionsData.get('ltdClass2').clearValidators();
      this.optionsData.get('ltdClass2').updateValueAndValidity();
      this.optionsData.get('dentalClass2').clearValidators();
      this.optionsData.get('dentalClass2').updateValueAndValidity();
      this.optionsData.get('eapClass2').clearValidators();
      this.optionsData.get('eapClass2').updateValueAndValidity();
      this.optionsData.get('teleMedClass2').clearValidators();
      this.optionsData.get('teleMedClass2').updateValueAndValidity();
      this.optionsData.get('expertMedClass2').clearValidators();
      this.optionsData.get('expertMedClass2').updateValueAndValidity();
      this.optionsData.get('hcsaClass2').clearValidators();
      this.optionsData.get('hcsaClass2').updateValueAndValidity();
      this.optionsData.get('psaClass2').clearValidators();
      this.optionsData.get('psaClass2').updateValueAndValidity();
    }
  }

  showHcsaC1() {
    var hcsa = this.optionsData.get('hcsaClass1');
    if (hcsa.value === 1) {
      this.optionsData.get('hcsaAmount1').setValidators([Validators.max(5000), Validators.min(250), Validators.required]);
    } else {
      this.optionsData.get('hcsaAmount1').clearValidators();
    }
    this.optionsData.get('hcsaAmount1').updateValueAndValidity();
  }

  showHcsaC2() {
    var hcsa = this.optionsData.get('hcsaClass2');
    if (hcsa.value === 1) {
      this.optionsData.get('hcsaAmount2').setValidators([Validators.max(5000), Validators.min(250), Validators.required]);
    } else {
      this.optionsData.get('hcsaAmount2').clearValidators();
    }
    this.optionsData.get('hcsaAmount2').updateValueAndValidity();
  }

  showPsaC1() {
    var hcsa = this.optionsData.get('psaClass1');
    if (hcsa.value === 1) {
      this.optionsData.get('psaAmount1').setValidators([Validators.max(5000), Validators.min(250), Validators.required]);
    } else {
      this.optionsData.get('psaAmount1').clearValidators();
    }
    this.optionsData.get('psaAmount1').updateValueAndValidity();
  }

  showPsaC2() {
    var hcsa = this.optionsData.get('psaClass2');
    if (hcsa.value === 1) {
      this.optionsData.get('psaAmount2').setValidators([Validators.max(5000), Validators.min(250), Validators.required]);
    } else {
      this.optionsData.get('psaAmount2').clearValidators();
    }
    this.optionsData.get('psaAmount2').updateValueAndValidity();
  }

  get employees(): FormArray {
    return this.employeeInfoForm.get('employees') as FormArray;
  }

  get eapClassArray(): FormArray {
    return this.employeeInfoForm.get('eapClasses') as FormArray;
  }

  get eapCostString(): String {
    if (this.eapCost === undefined) return;

    return this.eapCost.stringValue;
  }

  get proposedEffectiveDate(): FormControl {
    return this.generalInfoForm.get('proposedEffectiveDate') as FormControl;
  }

  get maxEmployees(): Number {
    if (this.maxEmployeeVariable === undefined) return;

    return this.maxEmployeeVariable.numericValue;
  }

  get maxEmployeesString(): String {
    if (this.maxEmployeeVariable === undefined) return;

    return this.maxEmployeeVariable.stringValue;
  }

  get minEmployees(): Number {
    if (this.minEmployeeVariable === undefined) return;

    return this.minEmployeeVariable.numericValue;
  }

  get minEmployeesString(): String {
    if (this.minEmployeeVariable === undefined) return;

    return this.minEmployeeVariable.stringValue;
  }

  get CheckFEDInstance(): Boolean {

    if (this.instanceValue != undefined) {

      if (this.instanceValue.stringValue.toUpperCase() === SystemVariable.FEDInstance) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  }
}
