<form [formGroup]="inputForm">
    <mat-form-field [ngClass]="cssClass" class="mg-10" style="width: 10rem;">
        <mat-label>{{ label }}</mat-label>
        <input 
            matInput 
            (ngModelChange)="emitChange($event)" 
            #input 
            formControlName="input"
            currencyMask
        >
    </mat-form-field>
</form>