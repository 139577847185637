<mat-stepper [labelPosition]="'bottom'" linear #stepper (selectionChange)="stepHandler($event)">
    <mat-step label="Welcome">
        <div class="row landingPage">
            <div class="row">
                <h2>Welcome to Essentials</h2>
                <p> Design a group benefit package that best fits the needs of your client. Ideal for small business owners with 3 - 15 employees </p>
            </div>
            <div class="content">
                <div>
                    <mat-card class="mat-card-home">
                        <h3> Fast and easy </h3>
                        <img src="../../assets/icon-snap.svg" alt="" width="124" height="124" />
                        <p> Instant customized quote for your client.<span *ngIf="screenWidth >= 1070"><br><br><br></span></p>
                    </mat-card>
                    <mat-card class="mat-card-home">
                        <h3> Stable pricing </h3>
                        <img src="../../assets/icon-stableCost.svg" alt="" width="124" height="124" />
                        <p> Rates are guaranteed for 2 years, making it easy for clients to
                            manage their budget.<span *ngIf="screenWidth >= 1070"><br><br></span></p>
                    </mat-card>
                    <mat-card class="mat-card-home">
                        <h3> Flexible </h3>
                        <img src="../../assets/icon-components.svg" alt="" width="124" height="124" />
                        <p> Mix and match plan options and select the commission rate that best
                            works
                            for you and your client.</p>
                    </mat-card>
                </div>
            </div>
        </div>
        <div class="divider">
            <mat-divider></mat-divider>
        </div>
        <div class="row step-btns">
            <button type="button" mat-raised-button matStepperNext class="good">Start Your Quote</button>
        </div>
    </mat-step>
    <mat-step label="Company Info" [formGroup]="generalInfoForm" [stepControl]="generalInfoForm">
        <div class="row">
            <h3>Broker Information</h3>
        </div>
        <div class="row">
            <mat-form-field class="mg-10" [hideRequiredMarker]="true">
                <mat-label>Broker Name</mat-label>
                <input matInput formControlName="brokerName">
                <mat-error>
                    <span *ngIf="generalInfoForm.get('brokerName')?.errors?.required">
                        This field is required.
                    </span>
                    <span *ngIf="generalInfoForm.get('brokerName')?.errors?.maxlength">
                        Must be fewer than {{ generalInfoForm.get('brokerName')?.errors?.maxlength.requiredLength }}
                        characters.
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="mg-10" [hideRequiredMarker]="true">
                <mat-label>Broker Email</mat-label>
                <input matInput formControlName="brokerEmail">
                <mat-error>
                    <span *ngIf="generalInfoForm.get('brokerEmail')?.errors?.required">
                        This field is required.
                    </span>
                    <span *ngIf="generalInfoForm.get('brokerEmail')?.errors?.email">
                        This must be a valid email.
                    </span>
                    <span *ngIf="generalInfoForm.get('brokerEmail')?.errors?.maxlength">
                        Must be fewer than {{ generalInfoForm.get('brokerEmail')?.errors?.maxlength.requiredLength }}
                        characters.
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="mg-10 commission" [hideRequiredMarker]="true">
                <mat-label>Commission</mat-label>
                <mat-select formControlName="commissionAmount">
                    <mat-option *ngFor="let comm of commissions$ | async" [value]="comm.id">
                        {{ comm.commissionValue }}%
                    </mat-option>
                </mat-select>
                <mat-error>This field is required.</mat-error>
            </mat-form-field>
        </div>
        <div class="row">
            <h3>Business Information</h3>
        </div>
        <div class="row">
            <mat-form-field class="mg-10" [hideRequiredMarker]="true">
                <mat-label>Legal Business Name</mat-label>
                <input matInput formControlName="businessName">
                <mat-error>
                    <span *ngIf="generalInfoForm.get('businessName')?.errors?.required">
                        This field is required.
                    </span>
                    <span *ngIf="generalInfoForm.get('businessName')?.errors?.maxlength">
                        Must be fewer than {{ generalInfoForm.get('businessName')?.errors?.maxlength.requiredLength }}
                        characters.
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="mg-10 province" [hideRequiredMarker]="true">
                <mat-label>Province</mat-label>
                <mat-select formControlName="provinceId">
                    <mat-option *ngFor="let prov of provinces$ | async" [value]="prov.id">
                        {{ prov.abbreviation }}
                    </mat-option>
                </mat-select>
                <mat-error>This field is required.</mat-error>
            </mat-form-field>
            <mat-form-field class="mg-10 proposedDate" [hideRequiredMarker]="true">
                <mat-label>Proposed Effective Date</mat-label>
                <mat-select formControlName="proposedEffectiveDate">
                    <mat-option *ngFor="let date of validDates$ | async" [value]="date.value">
                        {{ date.value }}
                    </mat-option>
                </mat-select>
                <mat-error>This field is required.</mat-error>
            </mat-form-field>
        </div>
        <div class="row ">
            <p class="generalMessages" *ngIf="showTenDayWarning()">Due to processing times, your group may not be
                completely setup by the proposed Effective Date. Coverage can be effective on this date, but claims
                cannot be submitted until your Group has been fully set up.</p>
        </div>
        <div class="divider">
            <mat-divider></mat-divider>
        </div>
        <div class="row step-btns">
            <button type="button" mat-raised-button matStepperPrevious class="bad">Back</button>
            <button type="button" mat-raised-button matStepperNext class="good">Next</button>
        </div>
    </mat-step>
    <mat-step label="Employee Info" [formGroup]="employeeInfoForm" [stepControl]="employeeInfoForm">
        <div formArrayName="employees" class="employees">
            <div class="employee" *ngFor="let employee of employees.controls; let j=index">
                <h4 class="mg-7">
                    Employee {{ j + 1 }} <span class="x" (click)="removeEmployee(j)"
                        *ngIf="employees.controls.length > minEmployees"><u> delete</u></span>
                </h4>
                <div [formGroupName]="j" class="row">
                    <mat-form-field class="mg-7" [hideRequiredMarker]="true">
                        <input matInput placeholder="Name" formControlName="name">
                        <mat-error>
                            <span *ngIf="employeeInfoForm.get('employees').at(j).get('name')?.errors?.required">
                                This field is required.
                            </span>
                            <span *ngIf="employeeInfoForm.get('employees').at(j).get('name')?.errors?.maxlength">
                                Must be fewer than {{
                                employeeInfoForm.get('employees').at(j).get('name')?.errors?.maxlength.requiredLength }}
                                characters.
                            </span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="mg-7 dob" [hideRequiredMarker]="true" floatLabel="always">
                        <mat-label>Date of Birth</mat-label>
                        <input type="date" matInput formControlName="dateOfBirth">
                        <mat-error>
                            <span *ngIf="employeeInfoForm.get('employees').at(j).get('dateOfBirth')?.errors?.required">
                                This field is required.
                            </span>
                            <span *ngIf="employeeInfoForm.get('employees').at(j).get('dateOfBirth')?.errors?.maxDob">
                                The oldest someone can be is 100.
                            </span>
                            <span *ngIf="employeeInfoForm.get('employees').at(j).get('dateOfBirth')?.errors?.minDob">
                                The youngest someone can be is 16.
                            </span>
                            <span
                                *ngIf="employeeInfoForm.get('employees').at(j).get('dateOfBirth')?.errors?.invalidDob">
                                Must be a valid birthday.
                            </span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="salary mg-7" [hideRequiredMarker]="true" floatLabel="always">
                        <mat-label>Annual Salary</mat-label>
                        <input matInput placeholder="Salary Amount" formControlName="salaryAmountId" currencyMask>
                        <mat-error>
                            <span
                                *ngIf="employeeInfoForm.get('employees').at(j).get('salaryAmountId')?.errors?.required
                                || employeeInfoForm.get('employees').at(j).get('salaryAmountId')?.errors?.SalaryEmptyCheck">
                                This field is required.
                            </span>
                            <span *ngIf="employeeInfoForm.get('employees').at(j).get('salaryAmountId')?.errors?.min">
                                Income must be a postive value.
                            </span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="gender mg-7" [hideRequiredMarker]="true" floatLabel="always">
                        <mat-label>Gender</mat-label>
                        <mat-select matNativeControl formControlName="genderId" placeholder="Select">
                            <mat-option *ngFor="let gender of genders$ | async" [value]="gender.id">
                                {{ gender.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error>This field is required.</mat-error>
                    </mat-form-field>
                    <mat-form-field class="familyStatus mg-7" [hideRequiredMarker]="true" floatLabel="always">
                        <mat-label>Family Status</mat-label>
                        <mat-select matNativeControl formControlName="familyStatusId" placeholder="Select">
                            <mat-option *ngFor="let status of familyStatuses$ | async" [value]="status.id">
                                {{ status.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error>This field is required.</mat-error>
                    </mat-form-field>
                    <mat-form-field class="class mg-7" [hideRequiredMarker]="true">
                        <mat-label>Class</mat-label>
                        <mat-select matNativeControl formControlName="classId"
                            (selectionChange)="updateEmployeesValidity()">
                            <mat-option *ngFor="let classType of classTypes" [value]="classType.id">
                                {{ classType.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            <span *ngIf="employeeInfoForm.get('employees').at(j).get('classId')?.errors?.planTypeClass">
                                At least one employee must be in Class 1
                            </span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="optout mg-7" [hideRequiredMarker]="true">
                        <mat-label>Waiving of coverage</mat-label>
                        <mat-select matNativeControl formControlName="optOutId">
                            <mat-option *ngFor="let optOut of optOutOptions" [value]="optOut.id">
                                {{ optOut.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            <span *ngIf="employeeInfoForm.get('employees').at(j).get('optOutId')?.errors?.required">
                                This field is required.
                            </span>
                            <span *ngIf="employeeInfoForm.get('employees').at(j).get('optOutId')?.errors?.CantOptOut">
                                Family Status cannot be single
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row-left">
            <button type="button" mat-raised-button (click)="addEmployee()"
                [disabled]="employees.controls.length == maxEmployees" class="utility bad"> + Add Employee</button>

            <span *ngIf="employees.controls.length == maxEmployees" class="mg-10">
                Please contact your National Sales Manager, Regional Group Manager, or groupsales&#64;wawanesa.com for
                additional employees
            </span>
        </div>
        <div class="employees default">
            <p class="mg-10">
                <b>Default values</b>
                <br><br>
                Change the default values for all employees on the plan.
            </p>
            <app-mass-input [data$]="salaryAmount$" [defaultValue]="" [key]="'salaryAmountId'" [label]="'Annual Salary'"
                [cssClass]="'salary'" (change)="massChange($event)">
            </app-mass-input>
            <app-mass-select [data$]="genders$" [defaultValue]="" [key]="'genderId'" [label]="'Gender'"
                [cssClass]="'gender'" (change)="massChange($event)">
            </app-mass-select>
            <app-mass-select [data$]="familyStatuses$" [defaultValue]="" [key]="'familyStatusId'"
                [label]="'Family Status'" [cssClass]="'familyStatus'" (change)="massChange($event)">
            </app-mass-select>
            <app-mass-select [syncData]="classTypes" [defaultValue]="" [key]="'classId'" [cssClass]="'class'"
                [label]="'Class'" (change)="massChange($event)">
            </app-mass-select>
        </div>
        <div class="divider">
            <mat-divider style="margin-bottom: 20px;"></mat-divider>
            <span *ngIf="showOptOutWarning()" class="centerWarning mg-10">
                Notice: An employee must have coverage under a plan provided by a Dependent Spouse in order to waive
                Health and Dental coverage.
            </span>
        </div>
        <div class="row step-btns">
            <button type="button" mat-raised-button matStepperPrevious class="bad">Back</button>
            <button type="button" mat-raised-button matStepperNext class="good">Next</button>
        </div>
    </mat-step>
    <mat-step label="Plan Selection" [formGroup]="optionsData" [stepControl]="optionsData">
        <div>
            <div class="row section">
                <div class="mg-r-rem">
                    <p><strong>Life and Accidental Death & Dismemberment (AD&D) (Plan Member) - Mandatory</strong></p>
                    <mat-form-field class="mg-10" [hideRequiredMarker]="true">
                        <mat-label>Class 1 selection:</mat-label>
                        <mat-select matNativeControl formControlName="addClass1">
                            <mat-option *ngFor="let option of options | slice:0:5" [value]="option.id">
                                {{option.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            <span *ngIf="optionsData.get('addClass1')?.errors?.required">
                                Selecting an option is required for each class.
                            </span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="mg-10" *ngIf=showOptionTwo [hideRequiredMarker]="true">
                        <mat-label>Class 2 selection:</mat-label>
                        <mat-select formControlName="addClass2">
                            <mat-option *ngFor="let option of options | slice:0:5" [value]="option.id">
                                {{option.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            <span *ngIf="optionsData.get('addClass2')?.errors?.required">
                                Selecting an option is required for each class.
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="ww-table mg-10" role="region" tabindex="0">
                    <table class="is-five-col is-first-large">
                        <thead>
                            <tr>
                                <th>Life & AD&D</th>
                                <th>Option 1</th>
                                <th>Option 2</th>
                                <th>Option 3</th>
                                <th>Option 4</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Maximum Benefit Amount / Benefit Formula
                                </td>
                                <td>$25,000</td>
                                <td>$50,000</td>
                                <td>$100,000</td>
                                <td>1x earnings (up to $150,000)</td>
                            </tr>
                            <tr>
                                <td>
                                    Benefit Reduction
                                </td>
                                <td colspan="4">Reduces by 50% at age 65, further reduces by 50% at age 70</td>
                            </tr>
                            <tr>
                                <td>
                                    Coverage Termination Age
                                </td>
                                <td colspan="4">At age 75 or retirement, whichever comes first</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div>
                <mat-divider></mat-divider>
            </div>

            <div class="row section">
                <div class="mg-r-rem">
                    <p><strong>Health (Plan Member and Dependents) - Mandatory</strong></p>
                    <mat-form-field class="mg-10" [hideRequiredMarker]="true">
                        <mat-label>Class 1 selection:</mat-label>
                        <mat-select formControlName="healthClass1">
                            <mat-option *ngFor="let option of options | slice:0:5" [value]="option.id">
                                {{option.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            <span *ngIf="optionsData.get('healthClass1')?.errors?.required">
                                Selecting an option is required for each class.
                            </span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="mg-10" *ngIf=showOptionTwo [hideRequiredMarker]="true">
                        <mat-label>Class 2 selection:</mat-label>
                        <mat-select formControlName="healthClass2">
                            <mat-option *ngFor="let option of options | slice:0:5" [value]="option.id">
                                {{option.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            <span *ngIf="optionsData.get('healthClass2')?.errors?.required">
                                Selecting an option is required for each class.
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="ww-table mg-10" role="region" tabindex="0">
                    <table class="is-five-col is-first-large">
                        <thead>
                            <tr>
                                <th>Health</th>
                                <th>Option 1</th>
                                <th>Option 2</th>
                                <th>Option 3</th>
                                <th>Option 4</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Reimbursement</td>
                                <td>80%</td>
                                <td>80%</td>
                                <td>100%</td>
                                <td>100%</td>
                            </tr>
                            <tr>
                                <td colspan="5" class="is-subhead">Prescription Drug Coverage (Pay Direct Drug Card)
                                </td>
                            </tr>
                            <tr>
                                <td>Calendar year maximum</td>
                                <td>$5,000</td>
                                <td>$10,000</td>
                                <td>$25,000</td>
                                <td>Unlimited</td>
                            </tr>
                            <tr>
                                <td colspan="5" class="is-subhead">Paramedical Services</td>
                            </tr>
                            <tr>
                                <td>Calendar year maximum per practitioner</td>
                                <td>$300</td>
                                <td>$400</td>
                                <td>$500</td>
                                <td>$500</td>
                            </tr>
                            <tr>
                                <td colspan="5" class="is-subhead">Medical Services and Supplies</td>
                            </tr>
                            <tr>
                                <td>
                                    Combined calendar year maximum
                                </td>
                                <td colspan="4">$10,000</td>
                            </tr>
                            <tr>
                                <td>Emergency Out of Country</td>
                                <td colspan="4">$5,000,000 lifetime maximum with 60 day trip limit and 100%
                                    reimbursement</td>
                            </tr>
                            <tr>
                                <td colspan="5" class="is-subhead">Vision</td>
                            </tr>
                            <tr>
                                <td>Frames, lenses, contacts & laser eye surgery maximum</td>
                                <td>No Coverage</td>
                                <td>$200/24 months</td>
                                <td>$300/24 months</td>
                                <td>$300/24 months</td>
                            </tr>
                            <tr>
                                <td>Coverage Termination Age</td>
                                <td colspan="4">At age 75 or retirement, whichever comes first</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div>
                <mat-divider></mat-divider>
            </div>

            <div class="row section">
                <div class="mg-r-rem">
                    <p><strong>Dependent Life (Dependents) - Optional</strong></p>
                    <mat-form-field class="mg-10" [hideRequiredMarker]="true">
                        <mat-label>Class 1 selection:</mat-label>
                        <mat-select formControlName="dependentClass1">
                            <mat-option *ngFor="let option of options | slice:0:4" [value]="option.id">
                                {{option.name}}
                            </mat-option>
                            <mat-option [value]=4>
                                Option 4 (Excluded)
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            <span *ngIf="optionsData.get('dependentClass1')?.errors?.required">
                                Selecting an option is required for each class.
                            </span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="mg-10" *ngIf=showOptionTwo [hideRequiredMarker]="true">
                        <mat-label>Class 2 selection:</mat-label>
                        <mat-select formControlName="dependentClass2">
                            <mat-option *ngFor="let option of options | slice:0:4" [value]="option.id">
                                {{option.name}}
                            </mat-option>
                            <mat-option [value]=4>
                                Option 4 (Excluded)
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            <span *ngIf="optionsData.get('dependentClass2')?.errors?.required">
                                Selecting an option is required for each class.
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="ww-table mg-10" role="region" tabindex="0">
                    <table class="is-five-col is-first-large">
                        <thead>
                            <tr>
                                <th>Dependent Life</th>
                                <th>Option 1</th>
                                <th>Option 2</th>
                                <th>Option 3</th>
                                <th>Option 4</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Spouse</td>
                                <td>$5,000</td>
                                <td>$10,000</td>
                                <td>$15,000</td>
                                <td rowspan="3">Excluded</td>
                            </tr>
                            <tr>
                                <td>Child</td>
                                <td>$2,500</td>
                                <td>$5,000</td>
                                <td>$7,500</td>
                            </tr>
                            <tr>
                                <td>
                                    Coverage Termination Age
                                </td>
                                <td colspan="3">When the Plan Member attains age 75 or retires, whichever comes first
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div>
                <mat-divider></mat-divider>
            </div>

            <div class="row section">
                <div class="mg-r-rem">
                    <p><strong>Critical Illness (CI) (Plan Member) - Optional</strong></p>
                    <mat-form-field class="mg-10" [hideRequiredMarker]="true">
                        <mat-label>Class 1 selection:</mat-label>
                        <mat-select formControlName="ciClass1">
                            <mat-option *ngFor="let option of options | slice:0:5" [value]="option.id">
                                {{option.name}}
                            </mat-option>
                            <mat-option [value]=5>
                                Option 5 (Excluded)
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            <span *ngIf="optionsData.get('ciClass1')?.errors?.required">
                                Selecting an option is required for each class.
                            </span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="mg-10" *ngIf=showOptionTwo [hideRequiredMarker]="true">
                        <mat-label>Class 2 selection:</mat-label>
                        <mat-select formControlName="ciClass2">
                            <mat-option *ngFor="let option of options | slice:0:5" [value]="option.id">
                                {{option.name}}
                            </mat-option>
                            <mat-option [value]=5>
                                Option 5 (Excluded)
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            <span *ngIf="optionsData.get('ciClass2')?.errors?.required">
                                Selecting an option is required for each class.
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="ww-table mg-10" role="region" tabindex="0">
                    <table class="is-five-col is-first-large">
                        <thead>
                            <tr>
                                <th>CI</th>
                                <th>Option 1</th>
                                <th>Option 2</th>
                                <th>Option 3</th>
                                <th>Option 4</th>
                                <th>Option 5</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Benefit Amount</td>
                                <td>$5,000 </td>
                                <td>$10,000 </td>
                                <td>$25,000 </td>
                                <td>$50,000 </td>
                                <td rowspan="3">Excluded</td>
                            </tr>
                            <tr>
                                <td>Covered Conditions</td>
                                <td colspan="4">29 covered conditions</td>
                            </tr>
                            <tr>
                                <td>Coverage Termination age</td>
                                <td colspan="4">At age 65 or retirement, whichever comes first</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div>
                <mat-divider></mat-divider>
            </div>

            <div class="row section">
                <div class="mg-r-rem">
                    <p><strong>Long-Term Disability (LTD) (Plan Member) – Optional</strong></p>
                    <mat-form-field class="mg-10" [hideRequiredMarker]="true">
                        <mat-label>Class 1 selection:</mat-label>
                        <mat-select formControlName="ltdClass1">
                            <mat-option *ngFor="let option of options | slice:0:4" [value]="option.id">
                                {{option.name}}
                            </mat-option>
                            <mat-option [value]=4>
                                Option 4 (excluded)
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            <span *ngIf="optionsData.get('ltdClass1')?.errors?.required">
                                Selecting an option is required for each class.
                            </span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="mg-10" *ngIf=showOptionTwo [hideRequiredMarker]="true">
                        <mat-label>Class 2 selection:</mat-label>
                        <mat-select formControlName="ltdClass2">
                            <mat-option *ngFor="let option of options | slice:0:4" [value]="option.id">
                                {{option.name}}
                            </mat-option>
                            <mat-option [value]=4>
                                Option 4 (excluded)
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            <span *ngIf="optionsData.get('ltdClass2')?.errors?.required">
                                Selecting an option is required for each class.
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="ww-table mg-10" role="region" tabindex="0">
                    <table class="is-five-col is-first-large">
                        <thead>
                            <tr>
                                <th>LTD</th>
                                <th>Option 1</th>
                                <th>Option 2</th>
                                <th>Option 3</th>
                                <th>Option 4</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Benefit Formula</td>
                                <td>66.67% of monthly earnings</td>
                                <td>66.67% of monthly earnings</td>
                                <td>66.67% of monthly earnings</td>
                                <td rowspan="6">Excluded</td>
                            </tr>
                            <tr>
                                <td>NEM/WEM</td>
                                <td colspan="3">Maximum of $2,500 without medical evidence or Maximum of $4,000 with
                                    medical evidence</td>
                            </tr>
                            <tr>
                                <td>Qualifying Period</td>
                                <td colspan="3">180 days</td>
                            </tr>
                            <tr>
                                <td>Maximum Benefit Period</td>
                                <td>2 years</td>
                                <td>5 years</td>
                                <td>To age 65</td>
                            </tr>
                            <tr>
                                <td>Definition of Disability</td>
                                <td>2 years own occupation</td>
                                <td>2 years own occupation, any occupation thereafter</td>
                                <td>2 years own occupation, any occupation thereafter</td>
                            </tr>
                            <tr>
                                <td>Coverage Termination Age</td>
                                <td colspan="3">Age 65 or retirement</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div>
                <mat-divider></mat-divider>
            </div>

            <div class="row section">
                <div class="mg-r-rem">
                    <p><strong>Dental (Plan Member & Dependents) - Optional</strong></p>
                    <mat-form-field class="mg-10" [hideRequiredMarker]="true">
                        <mat-label>Class 1 selection:</mat-label>
                        <mat-select formControlName="dentalClass1">
                            <mat-option *ngFor="let option of options | slice:0:5" [value]="option.id">
                                {{option.name}}
                            </mat-option>
                            <mat-option [value]=5>
                                Option 5 (excluded)
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            <span *ngIf="optionsData.get('dentalClass1')?.errors?.required">
                                Selecting an option is required for each class.
                            </span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="mg-10" *ngIf=showOptionTwo [hideRequiredMarker]="true">
                        <mat-label>Class 2 selection:</mat-label>
                        <mat-select formControlName="dentalClass2">
                            <mat-option *ngFor="let option of options | slice:0:5" [value]="option.id">
                                {{option.name}}
                            </mat-option>
                            <mat-option [value]=5>
                                Option 5 (excluded)
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            <span *ngIf="optionsData.get('dentalClass2')?.errors?.required">
                                Selecting an option is required for each class.
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="ww-table mg-10" role="region" tabindex="0">
                    <table class="is-six-col is-first-large">
                        <thead>
                            <tr>
                                <th>Dental</th>
                                <th>Option 1</th>
                                <th>Option 2</th>
                                <th>Option 3</th>
                                <th>Option 4</th>
                                <th>Option 5</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Basic Reimbursement</td>
                                <td>80%</td>
                                <td>80%</td>
                                <td>100%</td>
                                <td>100%</td>
                                <td rowspan="4">Excluded</td>
                            </tr>
                            <tr>
                                <td>Major Reimbursement</td>
                                <td>No coverage</td>
                                <td>50%</td>
                                <td>50%</td>
                                <td>60%</td>
                            </tr>
                            <tr>
                                <td>Maximum Benefit (Basic and Major Combined) </td>
                                <td>$1,000 per calendar year</td>
                                <td>$1,500 per calendar year</td>
                                <td>$2,000 per calendar year</td>
                                <td>$2,500 per calendar year</td>
                            </tr>
                            <tr>
                                <td>Coverage Termination age</td>
                                <td colspan="4">At age 75 or retirement, whichever comes first</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div>
                <mat-divider></mat-divider>
            </div>

            <div class="row section">
                <div class="mg-r-rem">
                    <p><strong>Employee Assistance Program (EAP) - Optional</strong></p>
                    <mat-form-field class="mg-10" [hideRequiredMarker]="true">
                        <mat-label>Class 1 selection:</mat-label>
                        <mat-select formControlName="eapClass1">
                            <mat-option *ngFor="let option of options | slice:0:2" [value]="option.id">
                                {{ option.name }}
                            </mat-option>
                            <mat-option [value]=2>
                                Option 2 (Excluded)
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            <span *ngIf="optionsData.get('eapClass1')?.errors?.required">
                                Selecting an option is required for each class.
                            </span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="mg-10" *ngIf=showOptionTwo [hideRequiredMarker]="true">
                        <mat-label>Class 2 selection:</mat-label>
                        <mat-select formControlName="eapClass2">
                            <mat-option *ngFor="let option of options | slice:0:2" [value]="option.id">
                                {{ option.name }}
                            </mat-option>
                            <mat-option [value]=2>
                                Option 2 (Excluded)
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            <span *ngIf="optionsData.get('eapClass2')?.errors?.required">
                                Selecting an option is required for each class.
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="ww-table mg-10" role="region" tabindex="0">
                    <table class="is-three-col is-first-small">
                        <thead>
                            <tr>
                                <th>EAP</th>
                                <th>Option 1</th>
                                <th>Option 2</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td>Included</td>
                                <td>Excluded</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>

            <div>
                <mat-divider></mat-divider>
            </div>

            <div class="row section">
                <div class="mg-r-rem">
                    <p><strong>Telemedicine - Optional</strong></p>
                    <mat-form-field class="mg-10" [hideRequiredMarker]="true">
                        <mat-label>Class 1 selection:</mat-label>
                        <mat-select formControlName="teleMedClass1">
                            <mat-option *ngFor="let option of options | slice:0:2" [value]="option.id">
                                {{option.name}}
                            </mat-option>
                            <mat-option [value]=2>
                                Option 2 (Excluded)
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            <span *ngIf="optionsData.get('teleMedClass1')?.errors?.required">
                                Selecting an option is required for each class.
                            </span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="mg-10" *ngIf=showOptionTwo [hideRequiredMarker]="true">
                        <mat-label>Class 2 selection:</mat-label>
                        <mat-select formControlName="teleMedClass2">
                            <mat-option *ngFor="let option of options | slice:0:2" [value]="option.id">
                                {{option.name}}
                            </mat-option>
                            <mat-option [value]=2>
                                Option 2 (Excluded)
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            <span *ngIf="optionsData.get('teleMedClass2')?.errors?.required">
                                Selecting an option is required for each class.
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="ww-table mg-10" role="region" tabindex="0">
                    <table class="is-three-col is-first-small">
                        <thead>
                            <tr>
                                <th>Telemedicine</th>
                                <th>Option 1</th>
                                <th>Option 2</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td>Included</td>
                                <td>Excluded</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>

            <div>
                <mat-divider></mat-divider>
            </div>

            <div class="row section">
                <div class="mg-r-rem">
                    <p><strong>Expert Medical Services (EMS) - Optional</strong></p>
                    <mat-form-field class="mg-10" [hideRequiredMarker]="true">
                        <mat-label>Class 1 selection:</mat-label>
                        <mat-select formControlName="expertMedClass1">
                            <mat-option *ngFor="let option of options | slice:0:2" [value]="option.id">
                                {{option.name}}
                            </mat-option>
                            <mat-option [value]=2>
                                Option 2 (Excluded)
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            <span *ngIf="optionsData.get('expertMedClass1')?.errors?.required">
                                Selecting an option is required for each class.
                            </span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="mg-10" *ngIf=showOptionTwo [hideRequiredMarker]="true">
                        <mat-label>Class 2 selection:</mat-label>
                        <mat-select formControlName="expertMedClass2">
                            <mat-option *ngFor="let option of options | slice:0:2" [value]="option.id">
                                {{option.name}}
                            </mat-option>
                            <mat-option [value]=2>
                                Option 2 (Excluded)
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            <span *ngIf="optionsData.get('expertMedClass2')?.errors?.required">
                                Selecting an option is required for each class.
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="ww-table mg-10" role="region" tabindex="0">
                    <table class="is-three-col is-first-small">
                        <thead>
                            <tr>
                                <th>EMS</th>
                                <th>Option 1</th>
                                <th>Option 2</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td>Included</td>
                                <td>Excluded</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>

            <div>
                <mat-divider></mat-divider>
            </div>

            <div class="row section">
                <div class="mg-r-rem">
                    <p><strong>Health Care Spending Account (HCSA) - Optional</strong></p>
                    <div class="saving-account">
                        <div style="flex-basis: 50%;">
                            <mat-form-field class="mg-10" [hideRequiredMarker]="true">
                                <mat-label>Class 1 selection:</mat-label>
                                <mat-select formControlName="hcsaClass1" (selectionChange)="showHcsaC1()">
                                    <mat-option *ngFor="let option of options | slice:0:2" [value]="option.id">
                                        {{option.name}}
                                    </mat-option>
                                    <mat-option [value]=2>
                                        Option 2 (Excluded)
                                    </mat-option>
                                </mat-select>
                                <mat-error>
                                    <span *ngIf="optionsData.get('hcsaClass1')?.errors?.required">
                                        Selecting an option is required for each class.
                                    </span>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="mg-10" [hideRequiredMarker]="true"
                                *ngIf="optionsData.get('hcsaClass1')?.value === 1">
                                <mat-label>$250 - $5000</mat-label>
                                <input formControlName="hcsaAmount1" matInput currencyMask [options]="{precision:0}">
                                <mat-error>
                                    <span *ngIf="optionsData.get('hcsaAmount1')?.errors?.max">
                                        This value is more than the maximum allowed.
                                    </span>
                                    <span *ngIf="optionsData.get('hcsaAmount1')?.errors?.min">
                                        This value is less than the minimum allowed.
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div style="flex-basis: 50%;">
                            <mat-form-field class="mg-10" *ngIf=showOptionTwo [hideRequiredMarker]="true">
                                <mat-label>Class 2 selection:</mat-label>
                                <mat-select formControlName="hcsaClass2" (selectionChange)="showHcsaC2()">
                                    <mat-option *ngFor="let option of options | slice:0:2" [value]="option.id">
                                        {{option.name}}
                                    </mat-option>
                                    <mat-option [value]=2>
                                        Option 2 (Excluded)
                                    </mat-option>
                                </mat-select>
                                <mat-error>
                                    <span *ngIf="optionsData.get('hcsaClass2')?.errors?.required">
                                        Selecting an option is required for each class.
                                    </span>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="mg-10" [hideRequiredMarker]="true"
                                *ngIf="showOptionTwo && optionsData.get('hcsaClass2')?.value === 1">
                                <mat-label>$250 - $5000</mat-label>
                                <input formControlName="hcsaAmount2" matInput currencyMask [options]="{precision:0}">
                                <mat-error>
                                    <span *ngIf="optionsData.get('hcsaAmount2')?.errors?.max">
                                        This value is more than the maximum allowed.
                                    </span>
                                    <span *ngIf="optionsData.get('hcsaAmount2')?.errors?.min">
                                        This value is less than the minimum allowed.
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="ww-table mg-10" role="region" tabindex="0">
                    <table class="is-three-col is-first-small">
                        <thead>
                            <tr>
                                <th>HCSA</th>
                                <th>Option 1</th>
                                <th>Option 2</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td>Included</td>
                                <td>Excluded</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>

            <div>
                <mat-divider></mat-divider>
            </div>

            <div class="row section">
                <div class="mg-r-rem">
                    <p><strong>Personal Spending Account (PSA) - Optional</strong></p>
                    <div class="saving-account">
                        <div style="flex-basis: 50%;">
                            <mat-form-field class="mg-10" [hideRequiredMarker]="true">
                                <mat-label>Class 1 selection:</mat-label>
                                <mat-select formControlName="psaClass1" (selectionChange)="showPsaC1()">
                                    <mat-option *ngFor="let option of options | slice:0:2" [value]="option.id">
                                        {{option.name}}
                                    </mat-option>
                                    <mat-option [value]=2>
                                        Option 2 (Excluded)
                                    </mat-option>
                                </mat-select>
                                <mat-error>
                                    <span *ngIf="optionsData.get('psaClass1')?.errors?.required">
                                        Selecting an option is required for each class.
                                    </span>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="mg-10" [hideRequiredMarker]="true"
                                *ngIf="optionsData.get('psaClass1')?.value === 1">
                                <mat-label>$250 - $5000</mat-label>
                                <input formControlName="psaAmount1" matInput currencyMask [options]="{precision:0}">
                                <mat-error>
                                    <span *ngIf="optionsData.get('psaAmount1')?.errors?.max">
                                        This value is more than the maximum allowed.
                                    </span>
                                    <span *ngIf="optionsData.get('psaAmount1')?.errors?.min">
                                        This value is less than the minimum allowed.
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div style="flex-basis: 50%;">
                            <mat-form-field class="mg-10" *ngIf=showOptionTwo [hideRequiredMarker]="true">
                                <mat-label>Class 2 selection:</mat-label>
                                <mat-select formControlName="psaClass2" (selectionChange)="showPsaC2()">
                                    <mat-option *ngFor="let option of options | slice:0:2" [value]="option.id">
                                        {{option.name}}
                                    </mat-option>
                                    <mat-option [value]=2>
                                        Option 2 (Excluded)
                                    </mat-option>
                                </mat-select>
                                <mat-error>
                                    <span *ngIf="optionsData.get('psaClass2')?.errors?.required">
                                        Selecting an option is required for each class.
                                    </span>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="mg-10" [hideRequiredMarker]="true"
                                *ngIf="showOptionTwo && optionsData.get('psaClass2')?.value === 1">
                                <mat-label>$250 - $5000</mat-label>
                                <input formControlName="psaAmount2" matInput currencyMask [options]="{precision:0}">
                                <mat-error>
                                    <span *ngIf="optionsData.get('psaAmount2')?.errors?.max">
                                        This value is more than the maximum allowed.
                                    </span>
                                    <span *ngIf="optionsData.get('psaAmount2')?.errors?.min">
                                        This value is less than the minimum allowed.
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="ww-table mg-10" role="region" tabindex="0">
                    <table class="is-three-col is-first-small">
                        <thead>
                            <tr>
                                <th>PSA</th>
                                <th>Option 1</th>
                                <th>Option 2</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td>Included</td>
                                <td>Excluded</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
            <div class="divider">
                <mat-divider></mat-divider>
            </div>
            <div class="row step-btns">
                <button type="button" mat-raised-button matStepperPrevious class="bad">Back</button>
                <button type="button" mat-raised-button matStepperNext class="good">Next</button>
            </div>
        </div>
    </mat-step>
    <mat-step label="View Quote" [completed]="optionsData.valid">
        <div class="quote-summary-container">
            <section class="quote-summary-price">
                <h1>
                    <span class="price-header">Your Proposal:</span>
                    <mat-spinner *ngIf="generatingQuote" style="margin:0 auto;"></mat-spinner>
                    <div *ngIf="!generatingQuote && quoteSuccess">
                        <span class="quote-price-large" aria-hidden="true">
                            <span class="quote-price-number">{{quoteAmount | currency}} </span>
                            <span class="quote-price-term">per month*</span>
                        </span>
                    </div>
                    <div *ngIf="!generatingQuote && !quoteSuccess">
                        <span class="quote-price-large" aria-hidden="true">
                            <span class="quote-price-error">{{ displayError }}</span>
                        </span>
                    </div>
                    <span class="quote-details">
                        The price displayed is the monthly total for all members. <br />
                        A full breakdown of costs is included in the proposal package. <br />
                        *Price does not include taxes.
                    </span>
                </h1>
            </section>
            <section class="quote-summary-cta">
                <h2>
                    To <strong>save your proposal</strong>, download the proposal package which includes all necessary
                    documents.
                </h2>
                <button class="good" mat-raised-button (click)="getDocument()" *ngIf="generatingQuote || quoteSuccess">
                    Download proposal package
                </button>
                <p class="quote-cta-actions">
                    <strong>Follow the steps on this page to complete the purchase.</strong>
                </p>
                <p>Need to make a change? Click <a class="link" (click)="editQuote()">edit quote.</a></p>
            </section>
        </div>
        <!-- Page content starts -->
        <main class="page-root">
            <section class="page-main">
                <h2>Completing the purchase</h2>
                <p>Complete the documents in the proposal package and send to Wawanesa Life:
                </p>

                <ul>
                    <li><b>Proposal</b> - should be submitted with other documents.</li>
                    <li><b>Group Benefits Application for Insurance</b> - the Plan Sponsor completes all sections</li>
                    <li><b>Employee Application</b> - each Employee completes one application.</li>
                </ul>

                <p>Completed documents should be saved as PDFs and sent to: <a
                        href="mailto:groupsales@wawanesa.com">groupsales&#64;wawanesa.com</a></p>


                <h2 class="h2-terms">Terms & Conditions</h2>
                <p>A Broker must have all appropriate Life and Accident and Sickness licenses in the appropriate
                    province to be able to offer this product to prospective clients.</p>

                <p>Further terms and conditions are available within the Proposal document. The coverage outlined in the
                    Proposal is subject to Wawanesa Life’s contract, administration, and claim adjudication guidelines.
                </p>

                <p>Wawanesa Life may use information entered onto this site in accordance with our <a
                        href="https://www.wawanesalife.com/pip/privacy.html" target="_blank">Privacy Policy.</a></p>

            </section>
            <section class="page-aside">

                <h3>Have questions?</h3>

                <p>For more information, please contact your National Sales Manager, Regional Group Manager, or <a
                        href="mailto:groupsales@wawanesa.com">groupsales&#64;wawanesa.com</a></p>

                <p>You may also consult the <a href="https://www.wawanesalife.com/group-insurance/group-brokers"
                        target="_blank">Essentials Product Guide</a> for further product information</p>

                <h3>Need something more customized?</h3>

                <p>If your prospective clients want greater levels of flexibility and different benefit coverage, please
                    contact us for a quote under our Choice product.</p>

                <br><br>

                <p>Did you know Wawanesa P&C brokers can easily create small business quotes through our Digital Small
                    Business tool? All you need is your Wawanesa Insurance broker code to get started.
                    <a href="https://login.brokerplatform.wawanesa.com/" target="_blank">Click here</a> or contact your
                    local business development representative for
                    more information.
                </p>

            </section>
        </main>
        <div class="divider">
            <mat-divider></mat-divider>
        </div>
        <div class="row step-btns">
            <button type="button" mat-raised-button matStepperPrevious class="bad">Back</button>
            <button class="left good" mat-raised-button type="button" (click)="reset()">New Quote</button>
        </div>
    </mat-step>
</mat-stepper>