import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { FamilyStatus } from "src/app/questions/classes/family-status.class";
import { Gender } from "src/app/questions/classes/gender.class";
import { PlanType } from "src/app/questions/classes/plan-type.class";
import { Province } from "src/app/questions/classes/province.class";
import { CurrentProvider } from 'src/app/questions/classes/current-provider.class';
import { SystemVariable } from 'src/app/questions/classes/system-variable.class';
import { CommissionRates } from 'src/app/questions/classes/commission.class';

@Injectable({
  providedIn: 'root'
})
export class ReferenceDataService {

  constructor(private http: HttpClient) { }
  
  getFamilyStatuses() : Observable<FamilyStatus[]> {
    return this.http.get<FamilyStatus[]>('/Reference/FamilyStatuses');
  }

  getGenders() : Observable<Gender[]> {
    return this.http.get<Gender[]>('/Reference/Genders');
  }

  getPlanTypes() : Observable<PlanType[]> {
    return this.http.get<PlanType[]>('/Reference/PlanTypes');
  }

  getProvinces() : Observable<Province[]> {
    return this.http.get<Province[]>('/Reference/Provinces');
  }

  getCommissions() : Observable<CommissionRates[]> {
    return this.http.get<CommissionRates[]>('/Reference/Commissions');
  }

  getCurrentProviders() : Observable<CurrentProvider[]> {
    return this.http.get<CurrentProvider[]>('/Reference/CurrentProviders');
  }
}
