import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { FormGroup, FormControl } from '@angular/forms';
import MassSelectChangeEvent from '../classes/events/mass-select-change.event';


@Component({
  selector: 'app-mass-input',
  templateUrl: './mass-input.component.html',
  styleUrl: './mass-input.component.css'
})
export class MassInputComponent {
  @Input() data$: Observable<number>;
  @Input() syncData: { id: number; name: string; }[];
  @Input() defaultValue: string;
  @Input() key: string;
  @Input() label: string;
  @Input() cssClass: string;
  @Output() change: EventEmitter<MassSelectChangeEvent> = new EventEmitter();

  @ViewChild("input", {static: true}) input: Input;
  inputForm: FormGroup;

  constructor() {
  }

  ngOnInit() {
    this.inputForm = new FormGroup({
      input: new FormControl(this.defaultValue)
    });
  }

  emitChange(event: number) {
    this.change.emit(MassSelectChangeEvent.create(this.key, event));
  }

  reset() {
    this.inputForm.setValue({ input: '' });
  }

}
