import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material/material.module';
import { QuestionsComponent } from './questions/questions.component';
import { ReactiveFormsModule, FormGroup, FormsModule } from '@angular/forms';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { PlansDialogueComponent } from './questions/plans-dialogue/plans-dialogue.component';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { environment } from "src/environments/environment";
import { BaseUrlInterceptor } from "src/app/services/base-url.interceptor.service";
import { MassSelectComponent } from './questions/mass-select/mass-select.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RequestCacheInterceptor } from './services/request-cache.interceptor.service';
import { MassInputComponent } from './questions/mass-input/mass-input.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';

@NgModule({ declarations: [
        AppComponent,
        QuestionsComponent,
        PlansDialogueComponent,
        MassSelectComponent,
        MassInputComponent
    ],
    bootstrap: [AppComponent], 
    imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        MomentDateModule,
        MatTooltipModule,
        CurrencyMaskModule], 
    providers: [
        HttpClient,
        { provide: "ENVIRONMENT", useValue: environment },
        { provide: HTTP_INTERCEPTORS,
            useClass: BaseUrlInterceptor,
            multi: true },
        { provide: HTTP_INTERCEPTORS,
            useClass: RequestCacheInterceptor,
            multi: true },
        provideHttpClient(withInterceptorsFromDi())
    ], 
    schemas: [CUSTOM_ELEMENTS_SCHEMA] 
})
export class AppModule { }
