import { FormGroup, FormControl, FormGroupDirective, NgForm, ValidatorFn } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormArray } from "@angular/forms";
import { PlanType } from './classes/plan-type.class';
import { ClassType } from './classes/class-type.class';

export class PlanTypeClass {
    public StateMatch = new class implements ErrorStateMatcher {
        constructor(public parent: PlanTypeClass) { }
        isErrorState(control: FormControl, form: FormGroupDirective | NgForm): boolean {
            return PlanTypeClass.validate(<FormGroup>control.parent) != null;
        }
    }(this);
    
    public static validate = function(group: FormGroup) {
        if(group.parent === undefined || group.parent === null) return;

        const arr: FormGroup[] = <FormGroup[]>(<FormArray>group.parent).controls;
        const class1 = arr.filter(o => o.get('classId').value === ClassType.One);

        const nameControl = group.get('name');

        if(class1.length > 0) {
            arr.forEach(x => {
                x.get('classId').setErrors(null)
                x.get('classId').markAsTouched();
            });
            return null;
        }
        else {
            arr.forEach(x => {
                x.get('classId').setErrors({'planTypeClass': true})
                x.get('classId').markAsTouched();
            });
            return 1;
        }  
    }
} 

export class PlanTypeAge {
    public StateMatch = new class implements ErrorStateMatcher {
        constructor(public parent: PlanTypeAge) { }
        isErrorState(control: FormControl, form: FormGroupDirective | NgForm): boolean {
            return (PlanTypeAge.validate(control) && (control.parent.parent.dirty || control.parent.parent.touched));
        }
    }(this);

    public static validate = function(control: FormControl) { 
        if(control.parent === null || control.parent === undefined || control.parent.pristine && !control.parent.touched) return;
        
        const dateOfBirth = control.parent.get('dateOfBirth').value;
        if (dateOfBirth === '' || dateOfBirth === null) return;

        var selectedDate = new Date(dateOfBirth);
        if(new Date().getFullYear() - selectedDate.getFullYear() > 100) {
            return { 'maxDob': true }
        } else if(new Date().getFullYear() - selectedDate.getFullYear() > 0 && 
          new Date().getFullYear() - selectedDate.getFullYear() < 16) {
            return { 'minDob': true }
        } else if(new Date().getFullYear() - selectedDate.getFullYear() <= 0) {
            return { 'invalidDob': true }
        }

        return null;
    }
}

export class BenefitOptionRequired {
    public StateMatch = new class implements ErrorStateMatcher {
        constructor(public parent: BenefitOptionRequired) { }
        isErrorState(control: FormControl, form: FormGroupDirective | NgForm): boolean {
            return (BenefitOptionRequired.validate(control) != null);
        }
    }(this);

    public static validate = function(control: FormControl) { 
        if(control.parent === null || control.parent === undefined) return;
        
        const selected = control.value;

        if(selected === 0) {
            return { 'optReq': true }
        }

        return null;
    }
}

export class SalaryEmptyCheck {
    public StateMatch = new class implements ErrorStateMatcher {
        constructor(public parent: SalaryEmptyCheck) { }
        isErrorState(control: FormControl): boolean {
            return SalaryEmptyCheck.validate(control) != null;
        }
    }(this);

    public static validate = function(control: FormControl) {
        if(control.parent === undefined || control.parent === null) return;
    
        const salary = control.parent.get('salaryAmountId')?.value;
        
        if(Number.isNaN(salary)) {
            return { 'SalaryEmptyCheck': true };
        }
    
        return null;
    }
}

export class FamilyOptOutCheck {
    public StateMatch = new class implements ErrorStateMatcher {
        constructor(public parent: FamilyOptOutCheck) { }
        isErrorState(control: FormControl, form: FormGroupDirective | NgForm): boolean {
            return FamilyOptOutCheck.validate(<FormGroup>control.parent) != null;
        }
    }(this);
    
    public static validate = function(group: FormGroup) {
        if(group.parent === undefined || group.parent === null) return;

        const arr: FormGroup[] = <FormGroup[]>(<FormArray>group.parent).controls;
        const optOutErrors = arr.filter(o => o.get('familyStatusId').value === 1 && o.get('optOutId').value !== 1);
        const optOutAllowed = arr.filter(o => (o.get('familyStatusId').value === 1 && o.get('optOutId').value === 1)
            || (o => o.get('familyStatusId').value === 2));

        if(optOutErrors.length > 0) {
            optOutErrors.forEach(x => {
                x.get('optOutId').setErrors({'CantOptOut': true})
                x.get('optOutId').markAsTouched();
            });
            return null;
        }
        if(optOutAllowed.length > 0) {
            optOutAllowed.forEach(x => {
                x.get('optOutId').setErrors(null)
                x.get('optOutId').markAsTouched();
            });
            return 1;
        }  
    }
} 