import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { SystemVariable } from 'src/app/questions/classes/system-variable.class';
import { QuoteInputDto } from 'src/app/questions/classes/dto/quote-input-dto';

@Injectable({
  providedIn: 'root'
})
export class QuoteDataService {

  constructor(private http: HttpClient) { }
  
  submitQuote(quoteData: QuoteInputDto) : Observable<number> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
  });
    return this.http.post<number>('/QuoteData/GenerateQuote', quoteData, { headers: headers })
  }

  getQuoteVariables() : Observable<SystemVariable[]> {
    return this.http.get<SystemVariable[]>('/QuoteData/QuoteVariables');
  }
}
