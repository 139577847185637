import { Injectable } from "@angular/core";
import { HttpResponse, HttpRequest } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class CacheService {
  private cache = new Map<string, [Date, HttpResponse<any>]>([]);

  public static IsCacheable(req: HttpRequest<any>) {
    return req.method == "GET";
  }

  get(key): HttpResponse<any> {
    const cachedResponse = this.cache.get(key);
    if (!cachedResponse) return null;

    const expires = cachedResponse[0];
    const httpResponse = cachedResponse[1];

    const now = new Date();
    if (expires && expires.getTime() < now.getTime()) {
      this.cache.delete(key);
      return null;
    }

    return httpResponse;
  }

  set(key: HttpRequest<any>, value: HttpResponse<any>, secondsToKeep: number) {
      const expires = new Date();
      expires.setSeconds(expires.getSeconds() + secondsToKeep);
      this.cache.set(key.url, [expires, value]);
  }
}