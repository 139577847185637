import { Injectable, Inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CacheService } from './cache.service';

@Injectable()
export class RequestCacheInterceptor implements HttpInterceptor {

    constructor(private cache: CacheService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (CacheService.IsCacheable(req)) {
          const cachedResponse = this.cache.get(req.url);
  
          return cachedResponse ? of(cachedResponse) : this.sendRequest(req, next);
        }
        else {
          return next.handle(req);
        }
    }

    sendRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(req).pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            this.cache.set(req, event, 240);
          }
        })
      );
    }
}