import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { FormGroup, FormControl } from '@angular/forms';
import MassSelectChangeEvent from '../classes/events/mass-select-change.event';

@Component({
  selector: 'app-mass-select',
  templateUrl: './mass-select.component.html',
  styleUrls: ['./mass-select.component.css']
})
export class MassSelectComponent implements OnInit {
  @Input() data$: Observable<{ id: number; name: string; }[]>;
  @Input() syncData: { id: number; name: string; }[];
  @Input() defaultValue: number;
  @Input() key: string;
  @Input() label: string;
  @Input() cssClass: string;
  @Output() change: EventEmitter<MassSelectChangeEvent> = new EventEmitter();

  @ViewChild("select", {static: true}) select: MatSelect;
  selectForm: FormGroup;

  constructor() {
  }

  ngOnInit() {
    this.selectForm = new FormGroup({
      select: new FormControl(this.defaultValue)
    });
  }

  emitChange(event: MatSelectChange) {
    this.change.emit(MassSelectChangeEvent.create(this.key, event.value));
  }

  reset() {
    this.select.value = this.defaultValue;
  }
}
