import { BenefitOptionsDto } from "./benefit-options-dto";
import { CompanyInfoDto } from "./company-info-dto";
import { EmployeeDto } from "./employee-dto";

export class QuoteInputDto
{
    CompanyInfo: CompanyInfoDto;
    Employees: Array<EmployeeDto>;
    BenefitOptions: BenefitOptionsDto;
    UUID: string;
}